<template>
  <div class="px-10"> <!---->
		<br></br>
    <div class="text-h5 mb-5 primary--text text-center">    <!--ขนาดข้อความหนา h4 ....5 สีข้อความprimary -->
      {{ isUpdate ? "แก้ไข" : "สร้าง" }}ข้อมูลสินค้าเพิ่มตัง <!--ปุ่ม?ข้อความ?เพื่อรับการอัปเดท--> 
    </div>
    <br></br>
    
		<v-row justify="center">
			<v-col cols="1"><div> รหัสสินค้า </div></v-col>
			<v-col cols="3">
				<v-text-field name="product_id" outlined dense hide-details  v-model="form.product_id" />
			</v-col>
			<v-col cols="1"></v-col>
			<v-col cols="1"><div> Unlimited </div></v-col>
			<v-col cols="3">
				<v-radio-group class="mt-1 mb-3" row v-model="form.is_unlimit" hide-details >
          <v-radio name="is_unlimit" label="ใช่" :value="true"></v-radio>
          <v-radio name="is_unlimit" label="ไม่ใช่" :value="false"></v-radio>
        </v-radio-group>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> ชื่อสินค้า </div></v-col>
			<v-col cols="3">
				<v-text-field
          name="name"
          outlined dense hide-details
          v-model="form.name"
        />
			</v-col>
			<v-col cols="1"></v-col>
			<v-col cols="1"><div> Max Bill </div></v-col>
			<v-col cols="3">
				<v-text-field
          name="bills"
          outlined dense hide-details
          v-model.number="form.bills"
        />
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> หมวดหมู่ </div></v-col>
			<v-col cols="3">
				<v-select 
          name="category" 
          item-value="value" 
          item-text="name" 
          outlined dense hide-details 
          v-model="form.category" 
          :items="list.category_dict"
        />
			</v-col>
      <v-col cols="1"></v-col>
			<v-col cols="1"><div> ใช้ได้ไม่จำกัดรอบ </div></v-col>
			<v-col cols="3">
				<v-radio-group class="mt-1 mb-3" row v-model="form.is_reuse_limit" hide-details >
          <v-radio name="is_reuse_limit" label="ใช่" :value="true"></v-radio>
          <v-radio name="is_reuse_limit" label="ไม่ใช่" :value="false"></v-radio>
        </v-radio-group>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> แบบ </div></v-col>
			<v-col cols="3">
				<v-select 
          item-value="value" 
          item-text="name"
          name="product_type" 
          outlined dense hide-details 
          v-model="form.product_type" 
          :items="list.product_type_dict" 
        />
			</v-col>
			<v-col cols="1"></v-col>
			<v-col cols="1"><div> อัพเกรดได้ </div></v-col>
			<v-col cols="3">
				<v-radio-group class="mt-1 mb-3" row v-model="form.can_upgrade" hide-details >
          <v-radio name="can_upgrade" label="ใช่" :value="true"></v-radio>
          <v-radio name="can_upgrade" label="ไม่ใช่" :value="false"></v-radio>
        </v-radio-group>
			</v-col>
		</v-row>

    <v-row justify="center">
			<v-col cols="1"><div> จำนวนเดือน/ปี </div></v-col>
			<v-col cols="3">
				<v-text-field 
          item-value="value" 
          item-text="name" 
          name="period" 
          outlined dense hide-details 
          v-model.number="form.period" 
        />
			</v-col>
			<v-col cols="1"></v-col>
			<v-col cols="1"><div>ตำแหน่งจัดเรียง</div></v-col>
			<v-col cols="3">
				<v-text-field 
          name="index"
          outlined dense hide-details
          v-model.number="form.index"
        />
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> ราคา </div></v-col>
			<v-col cols="3">
				<v-text-field 
          name="price" 
          outlined dense hide-details 
          v-model.number="form.price"
        />
			</v-col>
			<v-col cols="1"></v-col>
			<v-col cols="1"><div>สีของ tag</div></v-col>
			<v-col cols="3">
				<v-text-field 
          name="tagcolor"
          outlined dense hide-details
          v-model="form.tagcolor"
        />
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> ส่วนลด </div></v-col>
			<v-col cols="3">
				<v-text-field 
          name="discount" 
          outlined dense hide-details 
          v-model.number="form.discount"
        />
			</v-col>
			<v-col cols="5"></v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="1"><div> Vat </div></v-col>
			<v-col cols="3">
				<v-select 
          item-value="value" 
          item-text="name" 
          name="vat" 
          outlined dense hide-details 
          v-model="form.vat" 
          :items="list.vat_dict" 
        />
			</v-col>
			<v-col cols="5"></v-col>
		</v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn
          class="px-10 mr-15"
          color="grey" outlined
          @click="close()"
        >
          ปิด
        </v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          บันทึกข้อมูล
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script> 
import { required, maxLength, minLength, numeric, minValue, alpha } from "vuelidate/lib/validators";
import * as moment from "moment";

export default {
  validations: {
    form: {
      name: { required },
      bills: { required },
      period: { required },
      price: { required },
      product_id: { required },
    },
  },
  data: () => ({
    form: {
      index: 0,
      tagcolor: "",
      name: "",
      product_id: "",
      category: 0,
      product_type: 0,
      period: 0,
      price: 0,
      discount: 0,
      vat: 0,
      is_unlimit: false,
      bills: "",
      is_reuse_limit: false,
      can_upgrade: false,
      is_available: true,
    },

    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    list: {
      category_dict: [
        {value: 0, name: "หลัก"},
        {value: 1, name: "ทั่วไป"},
      ],
      product_type_dict: [
        {value: 0, name: "ครั้งเดียว"},
        {value: 1, name: "รายเดือน"},
        {value: 2, name: "รายปี"},
      ],
      vat_dict: [
        {value: 0, name:"0%"},
        {value: 1, name:"7%"},
      ]

    }
  }),

  async created() {
    if(this.isUpdate) await this.getData();
    this.loading = false;
  },

  methods: {
    async getData() {
      let body = {
        token: this.$jwt.sign({ _id: this.$route.params.id }, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_by_id`, body)
        .then(async (res) =>{
          this.form = { ...res.result };
          console.log('res',res);
        })
      .catch((err) => {
        console.log("err", err)
      })
    },
    async save() {
      this.$v.form.$touch();
      if(this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {

            let subPath = 'add_product'
            if(this.isUpdate) {
              subPath = 'edit_product'
              this.form = { product_id : this.form._id, ...this.form}
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, { noTimestamp: true }),
            };
            await this.$axios.post(`${this.$baseUrl}/backend/product/${subPath}`, body)
              .then(res=>{ 
                this.$alertSuccess({ title : res.status });
                // if(!this.isUpdate) 
                this.$router.push({name:'backoffice-product'});
              })
              .catch((err) => {
                console.log('err',err);
                this.$alertServerError({ title : err.error_message });
              })
            console.log("save()");
          }
          this.loading = false;
        }
      );
    },    

    close() {
      this.$router.push({ name: "backoffice-product" });
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
